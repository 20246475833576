import React, { Component, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import api from '../../services/api'
import { hasPermission } from '../../utils/hasPermission'
import { USER_PERMISSIONS } from '../../constants'


import { H3 } from '../common/Headers'
import { Spin } from '../common/Ant'

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]
const statusList = ['Closed', 'Open']

// Allow minutes to be in increments of 15
const MINUTE_TICKS = 15
const MAX_WINDOWS = 15

// Helper functions for time comparison
const timeToMinutes = (day, time) => {
  if (!time) return 0
  const [hours, minutes] = time.split(':').map(Number)
  return Number(day) * 24 * 60 + hours * 60 + minutes
}

const isOverlapping = (window1, window2) => {
  // Adapt end days do the case when the window crosses over Sunday night.
  const endDay1 =
    window1.startDay > window1.endDay ? window1.endDay + 7 : window1.endDay
  const endDay2 =
    window2.startDay > window2.endDay ? window2.endDay + 7 : window2.endDay

  // Convert to minutes since start of week
  const start1 = timeToMinutes(window1.startDay, window1.startTime)
  const end1 = timeToMinutes(endDay1, window1.endTime)
  const start2 = timeToMinutes(window2.startDay, window2.startTime)
  const end2 = timeToMinutes(endDay2, window2.endTime)

  return !(start1 >= end2 || end1 <= start2)
}

class InputField extends Component {
  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.number]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    match: PropTypes.object,
    label: PropTypes.string,
  }

  render() {
    return (
      <span className="flex flex-column mv1">
        <label className="f7 b db mb1 dark-gray" htmlFor={this.props.id}>
          {this.props.label}
        </label>
        <input
          className="input-reset ba b--black-20 pa1 db w-100 br2"
          type={this.props.type ? this.props.type : 'text'}
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          required={this.props.required ? this.props.required : false}
          disabled={this.props.disabled ? this.props.disabled : false}
          step={this.props.type === 'time' ? MINUTE_TICKS * 60 : undefined}
        />
      </span>
    )
  }
}

class SelectField extends Component {
  static propTypes = {
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    match: PropTypes.object,
    label: PropTypes.string,
  }

  render() {
    return (
      <span className="flex flex-column mv1">
        <label className="f7 b db mb1 dark-gray" htmlFor={this.props.id}>
          {this.props.label}
        </label>
        <select
          className="input-reset ba b--black-20 pa1 db w-100 br2 bg-white"
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          required
        >
          {this.props.options.map((e, index) => (
            <option key={index} value={index}>
              {e}
            </option>
          ))}
        </select>
      </span>
    )
  }
}

const FlowMonitorSchedule = ({ resourceId }) => {
  // State management
  const [isLoading, setIsLoading] = useState(true)
  const [windows, setWindows] = useState([
    {
      startDay: 0,
      startTime: '',
      endDay: 0,
      endTime: '',
      threshold: 0,
    },
  ])
  const [inactiveThreshold, setInactiveThreshold] = useState(0)
  const [inactiveValve, setInactiveValve] = useState(0)

  // ================
  // Helper Functions
  // ================
  const roundToNearestInterval = useCallback(timeString => {
    if (!timeString) return ''

    const [hours, minutes] = timeString.split(':').map(Number)
    const totalMinutes = hours * 60 + minutes
    const roundedMinutes =
      Math.round(totalMinutes / MINUTE_TICKS) * MINUTE_TICKS

    const newHours = Math.floor(roundedMinutes / 60)
    const newMinutes = roundedMinutes % 60

    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(
      2,
      '0'
    )}`
  }, [])

  // ================
  // API Functions
  // ================
  const getFlowSchedule = useCallback(() => {
    // Query api for flow schedule
    api
      .getFlowSchedule(resourceId)
      .then(data => {
        // Round existing times to nearest 15-minute interval
        const roundedWindows = data['windows'].map(window => ({
          ...window,
          startTime: roundToNearestInterval(window.startTime),
          endTime: roundToNearestInterval(window.endTime),
        }))

        setInactiveValve(data['inactiveStatus'])
        setInactiveThreshold(data['inactiveThreshold'])
        setWindows(roundedWindows)
        setIsLoading(false)
      })
      .catch(e => {
        console.log(e)
        setIsLoading(false)
      })
  }, [resourceId, roundToNearestInterval])

  // Effects
  useEffect(() => {
    getFlowSchedule()
  }, [resourceId, getFlowSchedule])

  // =================
  // Window Management
  // =================
  const handleAddWindow = () => {
    if (windows.length >= MAX_WINDOWS) {
      alert(`Maximum of ${MAX_WINDOWS} windows allowed`)
      return
    }
    setWindows([
      ...windows,
      {
        startDay: 0,
        startTime: '',
        endDay: 0,
        endTime: '',
        threshold: 0,
      },
    ])
  }

  const handleRemoveWindow = index => {
    const newWindows = [...windows]

    newWindows.splice(index, 1)
    setWindows(newWindows)
  }

  const handleCopyWindow = index => {
    if (windows.length >= MAX_WINDOWS) {
      alert(`Maximum of ${MAX_WINDOWS} windows allowed`)
      return
    }
    const newWindows = [...windows]
    const windowToCopy = { ...windows[index] }
    newWindows.splice(index, 0, windowToCopy)
    setWindows(newWindows)
  }

  const handleWindowInputChange = (index, event) => {
    const { name, value } = event.target
    const newWindows = [...windows]

    // Round time inputs to nearest interval
    if (name.includes('Time')) {
      newWindows[index][name] = roundToNearestInterval(value)
    } else if (name.includes('Day')) {
      newWindows[index][name] = parseInt(value, 10)
    } else {
      newWindows[index][name] = value
    }

    setWindows(newWindows)
  }

  // =================
  // Form Functions
  // =================
  const validateSchedule = () => {
    // Check window count
    if (windows.length > MAX_WINDOWS) {
      return {
        isValid: false,
        error: `Update not sent. Maximum of ${MAX_WINDOWS} windows allowed`,
      }
    }

    // Validate each window
    for (let i = 0; i < windows.length; i++) {
      const currentWindow = windows[i]

      // Check for empty required fields
      if (!currentWindow.startTime || !currentWindow.endTime) {
        return {
          isValid: false,
          error: `Update not sent. Window ${i + 1} has missing time values`,
        }
      }

      // Check for overlaps with other windows
      for (let j = 0; j < windows.length; j++) {
        if (i !== j && isOverlapping(currentWindow, windows[j])) {
          return {
            isValid: false,
            error: `Update not sent. Window ${i + 1} overlaps with Window ${j +
              1}`,
          }
        }
      }

      // Validate threshold is a number
      if (isNaN(currentWindow.threshold) || currentWindow.threshold < 0) {
        return {
          isValid: false,
          error: `Update not sent. Window ${i + 1} has invalid threshold value`,
        }
      }
    }

    // Validate inactive threshold
    if (isNaN(inactiveThreshold) || inactiveThreshold < 0) {
      return {
        isValid: false,
        error: 'Update not sent. Invalid inactive threshold value',
      }
    }

    return { isValid: true }
  }

  const handleSubmit = event => {
    event.preventDefault()

    // Validate schedule before submission
    const validation = validateSchedule()
    if (!validation.isValid) {
      alert(validation.error)
      return
    }

    setIsLoading(true)
    api
      .pushFlowSchedule(resourceId, windows, inactiveThreshold, inactiveValve)
      .then(resp => {
        getFlowSchedule()
      })
      .catch(e => {
        console.error(e)
      })
  }

  return isLoading ? (
    <Spin size="large" className="w-100 center mv5" />
  ) : (
    <form onSubmit={handleSubmit} className="pa black-80">
      <div className="mb3 ba b--black-10 br3 pa3 bg-white shadow-4">
        <span className="flex mb2">
          <H3 className="ma0 f4">Schedule Info</H3>
        </span>
        <div className="flex justify-around">
          <InputField
            label="Inactive Threshold (Gallons/min)"
            type="text"
            id="inactiveThreshold"
            name="inactiveThreshold"
            value={inactiveThreshold}
            onChange={e => {
              setInactiveThreshold(e.target.value)
            }}
            required={true}
          />
          <SelectField
            label="Inactive Status"
            options={statusList}
            id="inactiveValve"
            name="inactiveValve"
            value={inactiveValve}
            onChange={e => {
              setInactiveValve(e.target.value)
            }}
            required={true}
          />
        </div>
      </div>

      {windows.map((window, index) => (
        <div
          key={index}
          className="mb3 ba b--black-10 br3 pa3 bg-white shadow-4"
        >
          <div className="flex justify-between items-center mb2">
            <H3>Window {index + 1}</H3>
            {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
                <div className="flex">
                <button
                    type="button"
                    className="f7 link dim br2 ph2 pv1 dib white bg-dark-red bn mr2 h2"
                    onClick={() => handleRemoveWindow(index)}
                >
                    Remove
                </button>
                <button
                    type="button"
                    className="f7 link dim br2 ph2 pv1 dib white bg-dark-blue bn h2"
                    onClick={() => handleCopyWindow(index)}
                >
                    Copy
                </button>
                </div>
            ) : ""}
          </div>

          <div className="flex justify-between gap-1">
            <div className="w-1/5">
              <SelectField
                label="Start Day"
                options={daysOfWeek}
                id={`startDay-${index}`}
                name="startDay"
                value={window.startDay}
                onChange={e => {
                  handleWindowInputChange(index, e)
                }}
                required={true}
              />
            </div>
            <div className="w-1/5">
              <InputField
                label="Open Time"
                type="time"
                id={`startTime-${index}`}
                name="startTime"
                value={window.startTime}
                onChange={e => {
                  handleWindowInputChange(index, e)
                }}
                required={true}
              />
            </div>
            <div className="w-1/5">
              <SelectField
                label="End Day"
                options={daysOfWeek}
                id={`endDay-${index}`}
                name="endDay"
                value={window.endDay}
                onChange={e => {
                  handleWindowInputChange(index, e)
                }}
                required={true}
              />
            </div>
            <div className="w-1/5">
              <InputField
                label="Close Time"
                type="time"
                id={`endTime-${index}`}
                name="endTime"
                value={window.endTime}
                onChange={e => {
                  handleWindowInputChange(index, e)
                }}
                required={true}
              />
            </div>
            <div className="w-1/5">
              <InputField
                label="Threshold (Gallons/min)"
                type="text"
                id={`threshold-${index}`}
                name="threshold"
                value={window.threshold}
                onChange={e => {
                  handleWindowInputChange(index, e)
                }}
                required={true}
              />
            </div>
          </div>
        </div>
      ))}

      {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
        <div className="flex justify-between items-center">
            <button
            type="button"
            className="f7 link dim br2 ph3 pv2 dib white bg-dark-green bn"
            onClick={handleAddWindow}
            >
            Add Window
            </button>
            <button
            className="f7 link dim br2 ph3 pv2 dib white bg-blue bn"
            type="submit"
            >
            Save Changes
            </button>
        </div>
      ) : "" }
    </form>
  )
}

FlowMonitorSchedule.propTypes = {
  resourceId: PropTypes.number.isRequired,
}

export default FlowMonitorSchedule
