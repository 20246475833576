import React from 'react'
import cx from 'classnames'
import { icon, divIcon } from 'leaflet'
import gatewayOnline from '../../assets/floor-plan/gateway-online.svg'
import gatewayOffline from '../../assets/floor-plan/gateway-offline.svg'
import { Marker } from 'react-leaflet'
import { hasPermission } from '../../utils/hasPermission'
import { USER_PERMISSIONS } from '../../constants'


const MapMarkerHtml = (prefix, name, url) => {
    return `
      <div class="flex items-center justify-center">
        <img src=${url} class="w2 grow-large" height="36" width="36" />
      </div>
      <div class="flex justify-center marker-label">
        <span class="${prefix}">
          ${name}
        </span>
      </div>
    `
}

const GatewayMarker = (gateway, isEditing, handleDragend, handleClick) => {
    const { gatewayName, coordinates, gatewayId, online } = gateway

    let iconUrl = online ? gatewayOnline : gatewayOffline

    const prefix = cx('tc b ba b--moon-gray br1 pa1', {
      'bg-washed-green': online,
      'bg-washed-orange': !online,
    })

    const html = MapMarkerHtml(prefix, gatewayName, iconUrl)

    const gatewayIcon = hasPermission(USER_PERMISSIONS.VIEW_GATEWAY)
      ? divIcon({
          className: 'pod-icon',
          html
        })
      : icon({iconUrl})

    const draggableProps = isEditing
      ? {
          draggable: true,
          onDragend: handleDragend(gateway),
        }
      : {}

    return (
      coordinates && (
        <Marker
          position={coordinates}
          key={gatewayId}
          icon={gatewayIcon}
          onClick={() => handleClick(gateway)}
          {...draggableProps}
        />
      )
    )
}

export default GatewayMarker;