import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import SearchFilter from './common/SearchFilter'
import Select, { Option } from './common/Select'
import { hasPermission } from '../utils/hasPermission'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  USER_PERMISSIONS,
  VIEW_ALL,
  WATER_METER_TYPES,
} from '../constants'
import { configurePagination } from '../utils/helpers'

class AllWaterMeters extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.waterMeters,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      meterType: undefined,
    }
  }

  static propTypes = {
    getAllWaterMeters: PropTypes.func.isRequired,
    waterMeters: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    isWaterMeterLoading: PropTypes.bool.isRequired,
    deleteWaterMeter: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func,
    meta: PropTypes.object,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.waterMeters })
  }

  componentDidMount() {
    const { getAllWaterMeters } = this.props

    const { page, perPage, meterType } = parseQueryParams(global.location)
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      meterType,
    }

    this.setState(query)

    getAllWaterMeters(createQueryString(query))
  }

  handleChange = (pagination, filters, sorter) => {
    const { getAllWaterMeters, updateQueryParams } = this.props
    const { page, perPage, meterType } = this.state

    if (page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
        meterType,
      }
      const qs = createQueryString(query)

      getAllWaterMeters(qs)
      updateQueryParams({ search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllWaterMeters, updateQueryParams } = this.props
    const { page, perPage, meterType } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    const query = Object.assign(
      {
        page,
        perPage,
        meterType,
      },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    const qs = createQueryString(query)
    getAllWaterMeters(qs)
    updateQueryParams({
      search: qs,
    })
  }

  setSearchText = searchText => this.setState({ searchText })

  render() {
    const {
      deleteWaterMeter,
      windowWidth,
      isWaterMeterLoading,
      meta,
      getAllWaterMeters,
    } = this.props
    const {
      data,
      sortedInfo,
      searchText,
      page,
      perPage,
      meterType,
    } = this.state

    const isFixedColumn = windowWidth < 750

    const query = {
      page,
      perPage,
      meterType,
    }

    return (
      <section className="mb5 AllWaterMeters">
        {hasPermission(USER_PERMISSIONS.ADD_FLOW_MONITOR) && (
          <Anchor
            to="/inventory/water-meters/create"
            button
            className="mr3 mb3 dib"
          >
            Create New
          </Anchor>
        )}
        <div className="flex-l items-center-l">
          <Select
            className="w-100-l"
            placeholder="Meter Type"
            mode="multiple"
            input={{
              value: meterType,
              onChange: this.handleFilterChange('meterType'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...Object.keys(WATER_METER_TYPES)].map(x => (
              <Option value={x} key={x}>
                {WATER_METER_TYPES[x] || VIEW_ALL}
              </Option>
            ))}
          </Select>
        </div>
        <SearchFilter
          searchText={searchText}
          setSearchText={this.setSearchText}
          getItems={getAllWaterMeters}
          query={query}
        />
        {isWaterMeterLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="id"
            scroll={{ x: 650 }}
            bordered
            tableLayout="auto"
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
          >
            <Column
              fixed={'left'}
              title="Serial ID"
              dataIndex="meterSerialId"
              sorter={sortBy('meterSerialId')}
              sortOrder={
                sortedInfo.field === 'meterSerialId' && sortedInfo.order
              }
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Meter Type"
              dataIndex="meterType"
              sorter={sortBy('meterType')}
              sortOrder={sortedInfo.field === 'meterType' && sortedInfo.order}
              width={150}
              render={text => (text ? WATER_METER_TYPES[text] : '--')}
            />
            <Column
              title="Pipe ID (in.)"
              dataIndex="pipeId"
              sorter={sortBy('pipeId')}
              sortOrder={sortedInfo.field === 'pipeId' && sortedInfo.order}
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Max Flow (gpm)"
              dataIndex="pipeMaxFlow"
              sorter={sortBy('pipeMaxFlow')}
              sortOrder={sortedInfo.field === 'pipeMaxFlow' && sortedInfo.order}
              width={150}
              render={text => (text ? text : '--')}
            />
            <Column
              title="Min Flow (gpm)"
              dataIndex="pipeMinFlow"
              sorter={sortBy('pipeMinFlow')}
              sortOrder={sortedInfo.field === 'pipeMinFlow' && sortedInfo.order}
              width={150}
              render={text => (text ? text : '--')}
            />
            {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={50}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-water-meter">
                        <Anchor
                          to={`/inventory/water-meters/${record.id}/edit`}
                        >
                          Edit
                        </Anchor>
                      </Menu.Item>
                      <Menu.Item key="delete-water-meter">
                        <ActionAnchor
                          onClick={() => deleteWaterMeter(record.id)}
                        >
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )
                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllWaterMeters
